
import { Component, Vue, Prop } from 'vue-property-decorator';

import { AdminService, UserService } from '@/services';
import { ICauseCategorySettings, ICauseSettings } from '@/types/cause.types';
import { UserModule } from '@/store/modules';

@Component
export default class AdminCause extends Vue {
  @Prop() causes!: ICauseSettings[];
  @Prop() categories!: ICauseCategorySettings[];

  causeImageFile = null;
  causeHeaderImageFile = null;
  overlayImageFile = null;
  partnerLogoFile = null;
  causeUploadedImage: any = '';
  causeEmailUploadedImage: any = '';
  overlayUploadedImage: any = '';
  partnerLogoUploadedImage: any = '';

  dialog = false;
  isUpdateSettings = false;
  createMode = false;
  categoryOptions: any = null;

  defaultData = {
    id: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    deletedAt: null,
    description: '',
    name: '',
    logoUrl: '',
    emailHeaderImage: '',
    overlayUrl: '',
    partnerLogoUrl: '',
    title: '',
    primaryColor: '',
    secondaryColor: '',
    category: null,
    categoryId: ''
  };
  editedItem: ICauseSettings = this.defaultData;
  defaultItem: ICauseSettings = this.defaultData;
  editedIndex = -1;

  headers = [
    { text: 'NAME', value: 'name' },
    { text: 'TITLE', value: 'title' },
    { text: 'DESCRIPTION', value: 'description' },
    { text: 'EMAIL HEADER IMAGE', value: 'emailHeaderImage' },
    { text: 'BACKGROUND IMAGE', value: 'logoUrl' },
    { text: 'OVERLAY IMAGE', value: 'overlayUrl' },
    { text: 'PARTNER IMAGE', value: 'partnerLogoUrl' },
    { text: 'CATEGORY', value: 'category.name' },
    { text: 'ACTIONS', value: 'actions', sortable: false }
  ];

  onFilePickedCause(e: any): void {
    this.causeImageFile = e.target.files[0];

    const fr = new FileReader();
    fr.readAsDataURL(e.target.files[0]);
    fr.addEventListener('load', () => {
      this.causeUploadedImage = fr.result;
    });
  }

  onFilePickedEmailCause(e: any): void {
    this.causeHeaderImageFile = e.target.files[0];

    const fr = new FileReader();
    fr.readAsDataURL(e.target.files[0]);
    fr.addEventListener('load', () => {
      this.causeEmailUploadedImage = fr.result;
    });
  }

  onFilePickedOverlay(e: any): void {
    this.overlayImageFile = e.target.files[0];

    const fr = new FileReader();
    fr.readAsDataURL(e.target.files[0]);
    fr.addEventListener('load', () => {
      this.overlayUploadedImage = fr.result;
    });
  }

  onFilePickedPartnerLogo(e: any): void {
    this.partnerLogoFile = e.target.files[0];

    const fr = new FileReader();
    fr.readAsDataURL(e.target.files[0]);
    fr.addEventListener('load', () => {
      this.partnerLogoUploadedImage = fr.result;
    });
  }

  editItem(item: ICauseSettings): void {
    this.createMode = false;
    this.editedIndex = this.causes.indexOf(item);
    this.causeImageFile = null;
    this.causeUploadedImage = null;
    this.causeHeaderImageFile = null;
    this.causeEmailUploadedImage = null;
    this.overlayImageFile = null;
    this.overlayUploadedImage = null;
    this.partnerLogoFile = null;
    this.partnerLogoUploadedImage = null;
    this.editedItem = Object.assign({}, item);
    this.dialog = true;
    this.getCategoryOptions();
  }

  createNewCauseDialog(): void {
    this.causeImageFile = null;
    this.causeUploadedImage = null;
    this.causeHeaderImageFile = null;
    this.causeEmailUploadedImage = null;
    this.overlayImageFile = null;
    this.overlayUploadedImage = null;
    this.partnerLogoFile = null;
    this.partnerLogoUploadedImage = null;
    this.createMode = true; // Switch to create mode
    this.editedItem = Object.assign({}, { ...this.defaultData, category: { id: null } });
    this.dialog = true;
    this.getCategoryOptions();
  }

  close(): void {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, { ...this.defaultItem, category: { id: null } });
      this.editedIndex = -1;
    });
  }

  async deleteCause(): Promise<void> {
    this.isUpdateSettings = true;
    await AdminService.deleteCause(this.editedItem.id);
    this.isUpdateSettings = false;
    this.close();
    this.$emit('getCauses');
  }

  async save(): Promise<void> {
    this.isUpdateSettings = true;
    let formData = new FormData();
    if (this.causeImageFile) formData.append('logoUrl', this.causeImageFile as any);
    if (this.causeHeaderImageFile) formData.append('emailHeaderImage', this.causeHeaderImageFile as any);
    if (this.overlayImageFile) formData.append('overlayUrl', this.overlayImageFile as any);
    if (this.partnerLogoFile) formData.append('partnerLogoUrl', this.partnerLogoFile as any);
    formData.append('description', this.editedItem.description as any);
    formData.append('name', this.editedItem.name);
    formData.append('title', this.editedItem.title as any);
    formData.append('categoryId', this.editedItem.category.id as any);
    formData.append('primaryColor', this.editedItem.primaryColor as any);
    formData.append('secondaryColor', this.editedItem.secondaryColor as any);

    try {
      await AdminService.updateCause(this.editedItem.id, formData);
    } catch (e: any) {
      const errorMsg = this.$getError(e);
      this.$notifyError(errorMsg);
      this.isUpdateSettings = false;
      return;
    }

    let user = await UserService.fetchUser();
    UserModule.setUser(user);

    this.isUpdateSettings = false;
    this.close();
    this.$emit('getCauses');
  }

  async createNewCause(): Promise<void> {
    this.isUpdateSettings = true;
    let formData = new FormData();
    if (this.causeImageFile) formData.append('logoUrl', this.causeImageFile as any);
    if (this.causeHeaderImageFile) formData.append('emailHeaderImage', this.causeHeaderImageFile as any);
    if (this.overlayImageFile) formData.append('overlayUrl', this.overlayImageFile as any);
    if (this.partnerLogoFile) formData.append('partnerLogoUrl', this.partnerLogoFile as any);
    formData.append('description', this.editedItem.description as any);
    formData.append('name', this.editedItem.name);
    formData.append('title', this.editedItem.title as any);
    formData.append('categoryId', this.editedItem.category.id as any);
    formData.append('primaryColor', this.editedItem.primaryColor as any);
    formData.append('secondaryColor', this.editedItem.secondaryColor as any);

    try {
      await AdminService.createCause(formData);
    } catch (e: any) {
      const errorMsg = this.$getError(e);
      this.$notifyError(errorMsg);
      this.isUpdateSettings = false;
      return;
    }

    this.isUpdateSettings = false;
    this.close();
    this.$emit('getCauses');
  }

  getCategoryOptions(): { label: string; value: string }[] {
    try {
      const categories = this.categories;

      // Map the category objects to an array of label-value pairs
      this.categoryOptions = categories.map((category) => ({
        label: category.name,
        value: category.id
      }));
      return this.categoryOptions;
    } catch (error) {
      console.error('Error fetching and processing categories:', error);
      return [];
    }
  }

  async mounted(): Promise<void> {
    await this.getCategoryOptions();
  }
}
